import { ref, watch, computed } from '@vue/composition-api'
// Notification
import { debounce } from 'debounce'

export default function useDepositList() {
  // Use toast
  const refTransactionTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'id', label: 'User ID' },
    { key: 'username' },
    { key: 'fullName' },
    { key: 'amount' },
    { key: 'date' },
    { key: 'bankInfo' },
    { key: 'status' },
    { key: 'action' },
    { key: '' },
  ]

  const clientProfileList = [
    'VIP',
    'POSTCODE',
    'STATE',
    'NRL',
    'AFL',
    'SQUARE',
    'NOT RATED',
    'RESTRICTED',
    'SMART',
    'NORMAL',
    'SOFT',
    'HARD',
  ]

  const perPage = ref(100)
  const clientProfile = ref('NORMAL')
  const clientProfileVoucher = ref('NORMAL')

  const amountToken = ref(null)
  const amountVoucher = ref(null)

  const totalTransactions = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref(null)
  const isSortDirDesc = ref(false)
  const sortBy = ref(null)
  const selectedExpiredTime = ref(new Date().setDate(new Date().getDate() + 7))
  const selectedExpiredTimeVoucher = ref(new Date().setDate(new Date().getDate() + 7))

  const config = {
    enableTime: true,
    dateFormat: 'Y-m-d H:i:s',
    enableSeconds: true,
    disable: [{ from: '1970-1-1', to: new Date() }],
  }
  const dataMeta = computed(() => {
    const localItemsCount = refTransactionTable.value ? refTransactionTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalTransactions.value,
    }
  })

  const refetchData = () => {
    refTransactionTable.value.refresh()
  }

  watch([currentPage, perPage], () => {
    refetchData()
  })

  watch(
    [searchQuery],
    debounce(() => {
      totalTransactions.value = 0
      refetchData()
    }, 700),
  )

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveTransactionStatusVariant = status => {
    switch (status) {
      case 'Submitted':
        return 'warning'
      case 'Rejected':
        return 'danger'
      case 'Approved':
        return 'primary'
      case 'Completed':
        return 'success'
      default:
        return ''
    }
  }

  return {
    tableColumns,
    perPage,
    currentPage,
    totalTransactions,
    dataMeta,
    perPageOptions,
    searchQuery,
    refTransactionTable,
    isSortDirDesc,
    sortBy,
    clientProfileList,
    clientProfile,
    amountToken,
    resolveTransactionStatusVariant,
    selectedExpiredTime,
    selectedExpiredTimeVoucher,
    config,
    amountVoucher,
    clientProfileVoucher,
  }
}

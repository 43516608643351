<template>
  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <b-row class="mx-2 my-2">
        <b-col
          cols="12"
          md="2"
          class="d-flex align-items-center"
        >
          <div class="font-weight-bold">
            ADD REBUY TOKEN
          </div>
        </b-col>
        <b-col
          cols="12"
          md="10"
        >
          <b-row>
            <b-col
              cols="6"
            >
              <div>Client Profile</div>
              <b-form-select
                v-model="clientProfile"
                :options="clientProfileList"
                class="dropdown-select"
              />
            </b-col>
            <b-col
              cols="6"
            >
              <div>Expired Time</div>
              <flat-pickr
                id="openDate"
                v-model="selectedExpiredTime"
                class="form-control"
                :config="config"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row class="mx-2 mb-1">
        <b-col
          cols="0"
          md="2"
        />
        <b-col
          cols="12"
          md="10"
        >
          <b-row>
            <b-col
              cols="6"
            >
              <span>Add</span>
              <b-form-input
                id="deposit-value"
                v-model="amountToken"
                placeholder="Amount"
                type="number"
                class="deposit-value"
              />
            </b-col>

            <b-col
              cols="6"
            >
              <b-button
                variant="primary"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                class="btn-set d-block ml-auto mt-2"
                :disabled="loadingAddCoupon"
                @click="showModal(DepositAction.ADD_RE_BUY_TOKEN)"
              >

                Apply
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>

    <b-card
      no-body
      class="mb-0 mt-1"
    >
      <b-row class="mx-2 my-2">
        <b-col
          cols="12"
          md="2"
          class="d-flex align-items-center"
        >
          <div class="font-weight-bold">
            ADD VOUCHER
          </div>
        </b-col>
        <b-col
          cols="12"
          md="10"
        >
          <b-row>
            <b-col
              cols="6"
            >
              <div>Client Profile</div>
              <b-form-select
                v-model="clientProfileVoucher"
                :options="clientProfileList"
                class="dropdown-select"
              />
            </b-col>
            <b-col
              cols="6"
            >
              <div>Expired Time</div>
              <flat-pickr
                id="openDate"
                v-model="selectedExpiredTimeVoucher"
                class="form-control"
                :config="config"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row class="mx-2 mb-1">
        <b-col
          cols="0"
          md="2"
        />
        <b-col
          cols="12"
          md="10"
        >
          <b-row>
            <b-col
              cols="6"
            >
              <span>Add</span>
              <b-form-input
                id="deposit-value"
                v-model="amountVoucher"
                placeholder="Amount"
                type="number"
                class="deposit-value"
              />
            </b-col>

            <b-col
              cols="6"
            >
              <b-button
                variant="primary"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                class="btn-set d-block ml-auto mt-2"
                :disabled="loadingAddCoupon"
                @click="showModal(DepositAction.ADD_VOUCHER)"
              >
                Apply
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>

    <b-modal
      ref="modal"
      :title="modal.title"
      centered
      cancel-variant="outline-secondary"
      :ok-disabled="isLoading"
      @ok="handleSubmitModal"
    >
      <b-card-text class="text-black">
        {{ modal.content }}
      </b-card-text>
      <template #modal-ok>
        <Spinner v-if="isLoading" />
        <span v-else>Submit</span>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  VBModal,
  // BTable,
  // BPagination,
  // BBadge,
  // BFormCheckbox,
  BFormSelect,
  BButton,
  BCardText,
} from 'bootstrap-vue'
import { formatISO } from '@/helpers/utils'
import { mapActions, mapState } from 'vuex'
import {
  formatDateShort, formatPrice, formatBetBottom, formatBetTop,
} from '@core/utils/filter'
import useToastHandler from '@/services/toastHandler'
import flatPickr from 'vue-flatpickr-component'
import { DepositAction } from '@core/utils/enum/deposit'
import Spinner from '@core/components/Spinner'
import useDepositList from './useDepositList'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    // BTable,
    // BPagination,
    // vSelect,
    // BBadge,
    // InfoIcon,
    // EditIcon,
    // BFormCheckbox,
    BFormSelect,
    BButton,
    flatPickr,
    BCardText,
    Spinner,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      modal: {
        title: '',
        content: '',
      },
      action: {
        type: '',
        id: '',
      },
    }
  },
  computed: {
    ...mapState('user/detail', ['loadingAddCoupon']),
    isLoading() {
      return this.loadingAddCoupon
    },
  },

  methods: {
    ...mapActions({
      handleAddCoupon: 'user/detail/handleAddCoupon',
    }),

    setModal(title, content) {
      this.modal.title = title
      this.modal.content = content
    },

    showModal(type) {
      this.action = { type }

      if (type === DepositAction.ADD_RE_BUY_TOKEN) {
        if (this.amountToken <= 0) return
        this.setModal(
          'Add Re-buy Token',
          `Are you sure you want to add ${this.amountToken} re buy ${this.amountToken > 1 ? 'tokens' : 'token'} for all user with ${this.clientProfile} profile?`,
        )
      }

      if (type === DepositAction.ADD_VOUCHER) {
        if (this.amountVoucher <= 0) return
        this.setModal(
          'Add Voucher',
          `Are you sure you want to add ${this.amountVoucher} ${this.amountVoucher > 1 ? 'vouchers' : 'voucher'} for all user with ${this.clientProfile} profile?`,
        )
      }

      this.$refs.modal.show()
    },

    async handleAddCouponByType(type) {
      const res = await this.handleAddCoupon({
        profile: type === 'VOUCHER' ? this.clientProfileVoucher.replace(' ', '_') : this.clientProfile.replace(' ', '_'),
        amount: type === 'VOUCHER' ? this.amountVoucher : this.amountToken,
        couponType: type,
        expiredTime: type === 'VOUCHER' ? formatISO(this.selectedExpiredTimeVoucher) : formatISO(this.selectedExpiredTime),
      })
      if (res) {
        if (type === 'VOUCHER') {
          this.amountVoucher = null
        }
        if (type === 'REBUY_TOKEN') {
          this.amountToken = null
        }
        this.showToastSuccess('Success', `Add ${type} Successfully`)
      }
      return res
    },

    async handleSubmitModal(event) {
      event.preventDefault()
      const { type } = this.action

      if (type === DepositAction.ADD_RE_BUY_TOKEN) await this.handleAddCouponByType('REBUY_TOKEN')
      if (type === DepositAction.ADD_VOUCHER) await this.handleAddCouponByType('VOUCHER')

      if (!this.isLoading) {
        this.$nextTick(() => {
          this.$refs.modal.hide()
        })
      }
    },
  },
  setup() {
    const { showToastError, showToastSuccess } = useToastHandler()

    const {
      tableColumns,
      perPage,
      currentPage,
      totalTransactions,
      dataMeta,
      perPageOptions,
      isSortDirDesc,
      searchQuery,
      refTransactionTable,
      sortBy,
      clientProfileList,
      clientProfile,
      // UI
      amountToken,
      resolveTransactionStatusName,
      resolveTransactionStatusVariant,
      selectedExpiredTime,
      selectedExpiredTimeVoucher,
      config,
      amountVoucher,
      clientProfileVoucher,
      // Extra Filters
      // tnxIDFilter,
    } = useDepositList()

    return {
      // Sidebar
      tableColumns,
      perPage,
      currentPage,
      totalTransactions,
      dataMeta,
      perPageOptions,
      searchQuery,
      refTransactionTable,
      isSortDirDesc,
      sortBy,
      selectedExpiredTime,
      selectedExpiredTimeVoucher,
      config,
      // UI
      resolveTransactionStatusName,
      resolveTransactionStatusVariant,

      // Extra Filters
      // tnxIDFilter,

      showToastError,
      showToastSuccess,

      formatDateShort,
      formatPrice,
      formatBetBottom,
      formatBetTop,
      clientProfileList,
      clientProfile,
      amountToken,
      DepositAction,
      formatISO,
      amountVoucher,
      clientProfileVoucher,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: fit-content;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.margin-x-auto {
  margin-block: auto;
}
.box__info {
  max-width: 75px;
}

.label__input {
  color: #414753;
  opacity: 1 !important;
}

.box__edit {
  max-width: 55px;
  display: flex;
  justify-content: center;
}

.text-black {
  color: #000;
}

.btn-change-status {
  margin-left: auto;
  margin-top: auto;
  background: #2b65a5 !important;
}

.btn-change-status:hover {
  background: #2b65a5 !important;
}
.btn-change-status:focus {
  background: #2b65a5 !important;
}
</style>
